import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";

// TODO : use service worker
// https://angular.dev/ecosystem/service-workers/push-notifications

@Injectable({
  providedIn: 'root'
})
export class NtfyService {
  protected subscribeURL: string = "";
  protected eventSource: EventSource = null;

  public constructor() {
    this.subscribeURL = environment.ntfy_dsn + "/sse";
    this.eventSource = new EventSource(this.subscribeURL, {
      withCredentials: false
    });

    this.initializeV3();
  }

  private setupServiceWorker() {
    if ('serviceWorker' in navigator && navigator.serviceWorker.ready) {
      navigator.serviceWorker.ready.then((registration) => {
      });
    }
  }

  public initializeV3() {
    if ('serviceWorker' in navigator && navigator.serviceWorker.ready) {
      navigator.serviceWorker.ready.then((registration) => {
        Notification.requestPermission().then(permission => {
          this.eventSource.onmessage = (message) => {
            if (permission === 'granted') {
              let data = JSON.parse(message.data);
              console.log(data);
              registration.showNotification(data.title, {
                body: data.message,
                badge: data.badge,
                icon: data.icon,
                data: {url: data.click}
              });
            }
          }
        });
      })
    }
  }
}
