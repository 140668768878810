import {Route, Routes} from "@angular/router";
import {LayoutComponent} from "./layout/layout.component";
import {AuthGuard, NoAuthGuard} from "@makeo-packages/mkongusers";

export const appRoutes: Route[] = [
  // Public routes (NoAuthGuard)
  {
    path: '',
    component: LayoutComponent,
    data: { layout: 'empty' },
    children: [
      { path: 'inscription', loadChildren: () => import('app/views/auth/register/register.routes'), canActivate: [NoAuthGuard] },
      { path: 'compte-inactif', loadChildren: () => import('app/views/auth/inactive/inactive.routes'), canActivate: [NoAuthGuard] },
      { path: 'connexion', loadChildren: () => import('app/views/auth/auth.routes') },
      { path: 'auth/login', redirectTo: 'connexion' },
      { path: '', pathMatch: 'full', redirectTo: 'connexion' },
    ]
  },

  // Authenticated routes (AuthGuard)
  {
    path: '',
    component: LayoutComponent,
    data: { layout: 'empty' },
    children: [
      { path: 'prendre-rdv', loadChildren: () => import('app/views/call-rdv/appointments.routes'), canActivate: [AuthGuard] },
      { path: 'voyance-privee', loadChildren: () => import('app/views/call-rdv/private-call.routes'), canActivate: [AuthGuard] },
      { path: 'audiotel', loadChildren: () => import('app/views/audiotel/audiotel.routes'), canActivate: [AuthGuard] },
      //{path: 'account', loadChildren: () => import('app/views/account/account.routes')},
      { path: 'mes-rdv', loadChildren: () => import('app/views/account/account-appointments.routes'), canActivate: [AuthGuard] },
      { path: 'factures', loadChildren: () => import('app/views/account/account-invoices.routes'), canActivate: [AuthGuard] },
      { path: 'parametres', loadChildren: () => import('app/views/account/account-parameters.routes'), canActivate: [AuthGuard] },
      { path: '', loadChildren: () => import('app/views/home/home.routes'), canActivate: [AuthGuard] }
    ]
  },
];
