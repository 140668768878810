import {HTTP_INTERCEPTORS, provideHttpClient} from '@angular/common/http';
import {ApplicationConfig, importProvidersFrom, isDevMode, LOCALE_ID} from '@angular/core';
import {LuxonDateAdapter} from '@angular/material-luxon-adapter';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {provideAnimations} from '@angular/platform-browser/animations';
import {PreloadAllModules, provideRouter, RouterModule, withInMemoryScrolling, withPreloading,} from '@angular/router';
import {provideFuse} from '@fuse';
import {appRoutes} from 'app/app.routes';
// import { provideAuth } from 'app/core/auth/auth.provider';
// import { provideIcons } from 'app/core/icons/icons.provider';
// import { mockApiServices } from 'app/mock-api';
import {environment} from "../environments/environment";
import {MkongusersModule} from "@makeo-packages/mkongusers";
import {provideClientHydration} from "@angular/platform-browser";
import {provideServiceWorker} from "@angular/service-worker";
import {AuthInterceptor} from "./interceptors/auth.interceptor";
import {MkongtoolsModule} from "@makeo-packages/mkongtools";
import {mockApiServices} from "./mock-api";


export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom([
            RouterModule.forRoot(appRoutes, {
                // enableTracing: isDevMode(),  // console log all router events ...
                onSameUrlNavigation: 'reload',
                scrollPositionRestoration: 'enabled'
            }),
            // BrowserModule,
            // BrowserAnimationsModule,
            MkongusersModule,
            MkongtoolsModule,
            // NgxMatMomentModule,
            // NgxMatDatetimePickerModule,
            // MatDialogModule,
            // NgxMatTimepickerModule
        ]),

        provideAnimations(),
        provideHttpClient(),
        provideRouter(
            appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
        ),

        // PWA
        provideClientHydration(),
        // provideServiceWorker('ngsw-config.json', {
        //     enabled: true,
        //     //enabled: true, // (normal si pas de ngsw-worker.js en localhost)
        //     registrationStrategy: 'registerWhenStable:30000'
        // }),
        provideServiceWorker('service-worker.js', {
          enabled: isDevMode(),
          //enabled: true, // (normal si pas de ngsw-worker.js en localhost)
          registrationStrategy: 'registerWhenStable:30000'
        }),

        // Material Date Adapter
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'D',
                },
                display: {
                    dateInput: 'DDD',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },

       { provide: LOCALE_ID, useValue: 'fr-FR' },

        // Fuse
        //provideAuth(),
        //provideIcons(),

        provideFuse({
            mockApi: {
                delay: 0,
                services: mockApiServices,
            },
            fuse: {
                layout: 'classy',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-default',
                themes: [
                    {
                        id: 'theme-default',
                        name: 'Default',
                    },
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id: 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id: 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id: 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id: 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        }),
        // sinon : NullInjectorError: No provider for InjectionToken FUSE_APP_CONFIG!


        {
          provide: 'version', useValue: environment.version,
        },
        {
          provide: 'baseUrl', useValue: environment.apiAddress,
        },

        // {
        //   provide: 'debounceTime', useValue: environment.debounceTime || 1
        // },
        // {
        //   provide: 'isMailLogin', useValue: environment.isMailLogin || false
        // },
        // {
        //   provide: 'isRemenberMe', useValue: environment.isRemenberMe || false
        // },
        // {
        //   provide: 'isAccesFormRegister', useValue: environment.isAccesFormRegister || true
        // },
        // {
        //   provide: 'isAccesFormForgotPassword', useValue: environment.isAccesFormForgotPassword || true
        // },

        {
          provide: 'patternPasswordValidator', useValue: environment.patternPasswordValidator || ''
        },
        {
          provide: 'refreshTokenTtl', useValue: environment.refreshTokenTtl || 60
        },
        {
            provide: 'domain', useValue: environment.domain || ''
        },

        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true
        }
    ],
};
